import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Marquee from "react-fast-marquee"
import Slide from "../../utility/slide_content"
import IntegrationsCardNew from "../integration-tools-card-new"
import SVGIcon from "../SVGIcon"
import "./MarqueeImages.scss"

const MarqueeImages = props => {
  const { heading, images, newOpacity } = props
  return (
    <StaticQuery
      query={graphql`
        query integrationsImages {
          SuperOps {
            seoPosts(where: { pageName: Integrations }, orderBy: order_ASC) {
              order
              slug
              integrationCards {
                cardName
                subtext
                slug
                image {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className="marquee-images">
          <Slide delay="200">
            <h2 className="heading text-center fw-bold mx-auto font-roboto mb20">
              {heading}
            </h2>
          </Slide>

          <div className="position-relative">
            {newOpacity ? (
              <>
                <SVGIcon
                  name="opacityLeft2"
                  className="position-absolute opacity-img left d-dsk"
                />
                <SVGIcon
                  name="opacityRight2"
                  className="position-absolute opacity-img right d-dsk"
                />
              </>
            ) : (
              <>
                <SVGIcon
                  name="opacityLeft"
                  className="position-absolute opacity-img left d-dsk"
                />
                <SVGIcon
                  name="opacityRight"
                  className="position-absolute opacity-img right d-dsk"
                />
              </>
            )}
            <Marquee
              pauseOnClick
              pauseOnHover
              gradient={false}
              direction="left"
            >
              <IntegrationsCardNew
                data={props.integrations ? data.SuperOps.seoPosts : images}
                distance={props.distance}
                integrations={props.integrations}
              />
            </Marquee>
          </div>
        </section>
      )}
    />
  )
}

export default MarqueeImages
