import { css } from "@emotion/react"
import Tippy from "@tippyjs/react"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import SVGIcon from "../SVGIcon"
import "./ImagePointer.scss"

const ImagePointer = props => {
  const { image, title, text, subtext, cardDescription } = props.data
  const { version, trigger } = props

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const [tippyIndex, setTippyIndex] = useState(1)
  const showTippy = ind => {
    const tippyElement = document.querySelector(`.tippy-no-${ind}`)
    tippyElement._tippy.show()
    setTippyIndex(ind)
  }
  const triggerAll = () => {
    title.map((item, idx) => {
      showTippy(idx + 1)
    })
  }
  useEffect(() => {
    if (trigger === "auto") triggerAll()
    else showTippy(tippyIndex)
  }, [])

  return (
    <div
      className={`image-pointer ${version ? version : "version-v1"}`}
      style={{ background: `${cardDescription}` }}
    >
      <div className="bg-wrapper mx-auto">
        <div className="dot-bg mx-auto">
          <Slide delay="100">
            <Container className="Layout-container">
              <Col lg={10} className="mx-auto">
                <div className="prelative">
                  <SVGIcon
                    name="block/yellowLine"
                    className={`position-absolute yellow line d-dsk ${
                      version !== "version-v1" ? "dspnone" : ""
                    }`}
                  />
                  <SVGIcon
                    name="block/redLine"
                    className={`position-absolute line red d-dsk ${
                      version !== "version-v1" ? "dspnone" : ""
                    }`}
                  />
                  {title.map((item, ind) => {
                    return (
                      <Tippy
                        content={
                          <div
                            className={`${subtext[ind] === "right" &&
                              "flex-row-reverse"} ${subtext[ind] === "top" &&
                              "flex-column"} ${subtext[ind] === "bottom" &&
                              "flex-column-reverse"} d-flex align-items-center justify-content-center d-dsk`}
                          >
                            <div
                              className={`tooltip-text font-roboto fw-bold prelative ${subtext[ind]}`}
                            >
                              {text[ind]}
                            </div>
                            <div>
                              <SVGIcon
                                name="block/curvedBend"
                                className={`curved-bend ${subtext[ind]}`}
                              />
                            </div>
                          </div>
                        }
                        placement={subtext[ind]}
                        delay={[20, 0]}
                        inertia="true"
                        interactiveDebounce={0}
                        trigger="manual"
                        onHidden={() =>
                          trigger === "manual"
                            ? showTippy(tippyIndex)
                            : triggerAll()
                        }
                        offset={
                          subtext[ind] === "left" || subtext[ind] === "right"
                            ? [0, 0]
                            : [0, 0]
                        }
                        maxWidth="280px"
                      >
                        <div
                          className={`d-dsk position-absolute pulsating-circle tippy-no-${ind +
                            1}`}
                          css={css`
                            ${item}
                          `}
                          onClick={() => {
                            trigger === "manual"
                              ? showTippy(ind + 1)
                              : triggerAll()
                          }}
                        ></div>
                      </Tippy>
                    )
                  })}
                  <img
                    className="w-100 image prelative"
                    src={image[0].url}
                    alt="image"
                  />
                </div>
              </Col>

              {image[1]?.url && (
                <div
                  className={`mx-auto text-center mt24 ${
                    screenWidth >= 992 ? "w-50" : "w-100"
                  }`}
                >
                  <img className="w-100" src={image[1]?.url} alt="image" />
                </div>
              )}
            </Container>
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default ImagePointer
