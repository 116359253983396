import { Link } from "gatsby"
import React from "react"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import "../../styles/component/integration-card.scss"

const IntegrationsCardNew = (props) => {
  let countInc = 0
  const {data, distance, integrations} = props

  return (
    <div className="d-flex  integration-cards-new integration-parent">
      <div className="d-flex integration-cards-new int-child m-0">
        {data.map(item => {
          const { integrationCards, slug } = item
          if (countInc > data.length) {
            countInc = 0
          }
          return (
            <Fade
              bottom
              delay={(countInc + 1) * 50}
              duration="500"
              distance={`${distance ? distance : "40px"}`}
            >
              <span className="dspnone">{countInc++}</span>
              {integrations ? (
                <Link to={`/${slug}`}>
                  <div className="integration-card-new position-relative home-integration-card">
                    <LazyLoad once offset={100}>
                      <img
                        loading="lazy"
                        src={integrationCards.image[0]?.url}
                        alt={slug.slice(slug.indexOf("/") + 1)}
                      />
                    </LazyLoad>
                  </div>
                </Link>
              ) : (
                <>
                  <div className="integration-card-new position-relative home-integration-card">
                    <LazyLoad once offset={100}>
                      <img
                        loading="lazy"
                        src={item.url}
                        alt="image"
                      />
                    </LazyLoad>
                  </div>
                </>
              )}
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

export default IntegrationsCardNew
