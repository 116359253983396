import React, { useState } from "react"
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper/core"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import SVGIcon from "../SVGIcon"

SwiperCore.use([EffectCoverflow, Autoplay])

export default function TestimonialV6(props) {
  const [swipe, setSwipe] = useState()
  const [currentTestimonial, setCurrentTestimonial] = useState(0)
  const responsive = {
    992: {
      slidesPerView: "auto",
    },
    734: {
      slidesPerView: 1,
    },
    200: {
      slidesPerView: 1,
    },
  }

  const onchange = data => {
    setCurrentTestimonial(data)
  }
  const CustomButtonGroup = () => {
    return (
      <div className="button-group d-flex align-items-center justify-content-center gap-40">
        <button
          className="carousel-button carousel-btn--prev"
          onClick={() => {
            swipe.slidePrev()
          }}
        >
          <SVGIcon
            name="arrowLeftNew"
            className="carousel-btn-arrow--left arrow btn-secondary-new p-0"
          />
        </button>
        <span className="swiper-pagination fw-600 text-deep-purple">
          {currentTestimonial + 1}/{props.testimonial.length}
        </span>
        <button
          className="carousel-button carousel-btn--next"
          onClick={() => {
            swipe.slideNext()
          }}
        >
          <SVGIcon
            name="arrowRightNew"
            className="carousel-btn-arrow--right arrow  btn-secondary-new p-0"
          />
        </button>
      </div>
    )
  }

  return (
    <div className="multiple-6 prelative">
      <div className="swiper-container">
        <Swiper
          pagination={false}
          effect={"fade"}
          centeredSlides={true}
          spaceBetween={30}
          slidesPerView={"auto"}
          loop={true}
          onSlideChange={data => {
            onchange(data.realIndex)
          }}
          onBeforeInit={swipper => setSwipe(swipper)}
          speed={750}
          breakpoints={responsive}
          slideToClickedSlide={true}
          fadeEffect={{
            crossFade: true,
          }}
          className="mySwiper"
        >
          {props.testimonial.map((item, i) => (
            <SwiperSlide>
              <div className="d-flex">
                <div className="first img-wrapper position-relative">
                    <img src={item.image.url} alt={item.name} className="w-100"/>
                </div>
                <div className="main-card d-flex flex-column justify-content-between">
                  <p className="font-roboto fw-bold content mb40">{item.content}</p>
                  <div>
                    <h4 className="font-roboto fw-800 name">{item.name}</h4>
                    <p className="p14 designation m-0">{item.title}</p>
                  </div>
                  <div className="second img-wrapper mt40 position-relative mx-auto">
                    <img src={item.image.url} alt={item.name} className="w-100" />
                  </div>
                </div>
                
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <CustomButtonGroup />
    </div>
  )
}
